import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Avatar, Grid, Box, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddMetadataForm from '../addmetadata';
import { motion } from 'framer-motion';
import { useAuth0 } from "@auth0/auth0-react";
import { css, keyframes } from '@emotion/react';
const ProfilePage = ({ setDisplayedPage}) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [profile, setProfile] = useState({
    name: '',
    role: '',
    email: '',
    emailVerification: false,
    contact: '',
    mobileVerification: '',
    status: '',
    imageUrl: '',
    user_data:[],
  });
  const [idx, setIdx] = useState(0)

  const [loading, setLoading] = useState(true); // State to handle loading
  const fetchProfile = async () => {
    if(user){
      setLoading(true); // Start loading
      const url = `https://facticity-backend-a4xncz5jja-as.a.run.app/get-user/${user.email}`;
      // const url = `http://localhost:5000/get-user/${user.email}`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data)
      console.log(user.sub)
      let profile;
      if (data[0]?.user_data[0]?.user_metadata) {
        
        if (data[0]?.user_data[0]) {
          profile = data[0];
          setIdx(0);
        } else if (data[1]?.user_data[1]?.user_metadata) {
          profile = data[1];
          setIdx(1);
        } else {
          profile = null;
        }

        if (profile) {
          setProfile(profile);
        } else {
          console.error('No user metadata found');
        }

      } 
      

      setProfile(data[0]);
      setLoading(false); // Stop loading after data is fetched  
    }
  };

  useEffect(() => {
    console.log({user})
    fetchProfile();
  }, [user]); // Dependency array to re-run useEffect if user.email changes


  useEffect(() => {
    // console.log({user})
  }, []); // Dependency array to re-run useEffect if user.email changes



  const { name, role, email, emailVerification, contact, mobileVerification, status, imageUrl } = profile;

  if (loading) {
    if (user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
    }
  }
  const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const centerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const textStyle = css`
  font-size: 40px;
  animation: ${fadeInOut} 2s infinite;
`;
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      
      {user ? (
        <>
          <br />
          <br />
          <br />
          
          <Grid container spacing={2} justifyContent="center">
            <Grid item sm={2}></Grid> {/* Left margin for larger screens */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} justifyContent="center">
                <ArrowBackIcon onClick={()=>{setDisplayedPage('home')}}/>
                <Grid item md={6}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <Avatar
                      src={user.imageUrl}
                      sx={{ width: 90, height: 90, mb: 2 }}
                      alt={user.name}
                    />
                    <Typography variant="h6">{user.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user.contact}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <CardContent>
                  <Grid container>
                    <DetailItem title="Email" detail={user.email} />
                    <DetailItem title="Email verification" detail={profile.emailVerification ? "Verified" : "Pending"} />
                    {/* <DetailItem title="Subscription status" detail={"Free tier"} /> */}
                    {profile.user_data[idx].user_metadata && (
                      <>
                        {/* <DetailItem title="Name" detail={user.name} /> */}
                        <DetailItem title="Name" detail={profile.user_data[idx].user_metadata.first_name+" "+profile.user_data[idx].user_metadata.last_name} />
                        <DetailItem title="Company" detail={profile.user_data[idx].user_metadata.company} />
                        <DetailItem title="Occupation" detail={profile.user_data[idx].user_metadata.occupation} />
                        <DetailItem title="LinkedIn" detail={profile.user_data[idx].user_metadata.linkedin} />
                        <DetailItem title="Industry" detail={profile.user_data[idx].user_metadata.industry} />
                      </>
                    )}
                  </Grid>
                  </CardContent>
                </Grid>
              </Grid>
              <AddMetadataForm email={user.email} id={profile.user_data[idx].user_id} updateData={fetchProfile}/>
            </Grid>
            <Grid item sm={2}></Grid> {/* Right margin for larger screens */}
          </Grid>
        </>
      ) : (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} css={centerStyle}>
              <Typography sx={{ fontSize: 40 }} css={textStyle}>
                Logging you in...
              </Typography>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{ textAlign: 'center' }}>
                <motion.div
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                >
                  <Typography sx={{ fontSize: 40 }}>
                    Please <span style={{ color: '#7407FF' }}>sign in</span> or <br /> <span style={{ color: '#7407FF' }}>sign up</span> and <br />navigate to the profile page to continue
                  </Typography>
                </motion.div>
              </div>
            </div>
          )}
        </div>

      )}
    </Box>
  );
};

// Helper component for detail items
const DetailItem = ({ title, detail }) => (
  <Grid item xs={12} sx={{ pb: 1 }}>
    <Typography variant="body1" color="textSecondary" component="span">{title}: </Typography>
    <Typography variant="body1" component="span">{detail}</Typography>
  </Grid>
);

export default ProfilePage;

